import React, { useState, useEffect } from 'react';
import { Tween } from 'react-gsap';
import { Link } from 'gatsby';
import { globalHistory } from '@reach/router';
import { Background, Parallax } from 'react-parallax';
import { replaceSubDomain } from '../../utils';

const Portfolio = ({ title, allProjectsLink, projectsList }) => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [scale, setScale] = useState(0);
  projectsList.length = 4;
  const [lang, setLange] = useState('');

  useEffect(() => {
    setLange(globalHistory.location.pathname.split('/')[1]);
  }, []);

  const updateMousePosition = (e) => {
    e.persist();
    setMousePosition(() => {
      return {
        x: e.pageX - 25,
        y: e.pageY - 110,
      };
    });
  };
  require.context('../../images', true);

  const onPointerEnter = () => {
    setScale(1);
  };

  const onPointerLeave = () => {
    setScale(0);
  };

  return (
    <div className='portfolio-container'>
      <section className="portfolio">
        <div className="title">
          <div className="h1">{title}</div>
          {/*<Link to={`/${lang}/portfolio`} className="font-size-lg">{allProjectsLink}</Link>*/}
          <Link to={`/${lang}/portfolio`} className="font-size-lg view-all">
            {allProjectsLink}
          </Link>
        </div>

        <div className="portfolio">
          <div className="portfolio-row">
            {projectsList.map((item, id) => (
              <div className="portfolio-item" key={id}>
                {id < 4 && (
                  <div>
                    <div className="portfolio-image-wrap">
                      <Link
                        to={`/${lang}/${item.url}`}
                        className="portfolio-image"
                        onPointerEnter={onPointerEnter}
                        onPointerLeave={onPointerLeave}
                        onPointerMove={updateMousePosition}
                      >
                        <Parallax
                          bgImageAlt=""
                          strength={200}
                          bgImageSize="cover"
                          className={
                            id > 3 ? 'paralax-img' : 'paralax-img-wide'
                          }
                          blur={0}
                        >
                          <Background className="background_wrapper">
                            <img
                              src={replaceSubDomain(item.image.desktop)}
                              alt=""
                            />
                          </Background>
                        </Parallax>
                      </Link>
                    </div>

                    <div className="portfolio-text">
                      <Link to={`/${lang}/${item.url}`}>
                        <h2>{item.title}</h2>
                      </Link>
                      <p>{item.description}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <Tween
        to={{
          x: mousePosition.x,
          y: mousePosition.y,
          scale: scale,
          opacity: scale,
        }}
        duration={0.3}
      >
        <div className="circle" />
      </Tween>
    </div>
  );
};

export default Portfolio;
