import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Feedback = (props) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    // autoplay: true,
    // autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const {
    title,
    feedbackList
  } = props;
  return (
    <section className="section feedback-container">
      <div className="container">
        <div className="h1 text-center home-testimonials-title">{title}</div>
        <div className="quotes">

          <Slider {...sliderSettings}>
            {
              feedbackList.map((item, id) => (
                <div className="slider-item" key={id}>
                  <blockquote className="blockquote">
                    {
                      item.message
                    }
                    <footer className="blockquote-footer">
                      <div className="h3 m-0">{item.author}</div>
                      {item.role}
                    </footer>
                  </blockquote>
                </div>
              ))
            }
          </Slider>

        </div>
      </div>
    </section>
  )
};

export default Feedback;
