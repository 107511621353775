import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useState } from 'react';
import { Background, Parallax } from 'react-parallax';
import { replaceSubDomain } from '../../utils';
import ContactUsAndThanks from '../CombineModals/ContactUsAndThanks';

const Hero = ({ image, btnText, children }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModalHandler = () => {
    setIsOpenModal(true);
  };
  const closeModalHandler = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="hero-wrap">
      <Parallax
        data-aos={'zoom-in'}
        bgImage={replaceSubDomain(image)}
        bgImageAlt=""
        strength={200}
        blur={0}
      >
        <Background>
          <div className="animate-hero-image"></div>
        </Background>
        <div className="hero">
          <div className="container">
            <div className="hero-title" data-aos={'fade-up'}>
              {children.split(' ').map((title, item) => {
                return <p key={item}>{title}</p>;
              })}
            </div>

            <button
              className="btn btn-lg btn-primary rounded-pill"
              data-aos={'fade-up'}
              onClick={openModalHandler}
            >
              <span className="pl-3 pr-3 ml-1 mr-1">{btnText}</span>
            </button>
          </div>
        </div>
      </Parallax>
      <ContactUsAndThanks show={isOpenModal} onHide={closeModalHandler} /> 
    </div>
  );
};

export default Hero;
