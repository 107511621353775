import React, { useEffect, useState } from 'react';
import { globalHistory } from '@reach/router';
import { replaceSubDomain } from '../../utils';
import { Link } from 'gatsby';

const Service = ({
  services,
  servicesItems,
  domainItem,
  businessDomainTitle,
}) => {
  const [lang, setLange] = useState('');
  useEffect(() => {
    setLange(globalHistory.location.pathname.split('/')[1]);
  }, []);
  return (
    <section className="service-section section gradient-white-gray ">
      <div className="container service">
        <div className="service-header">
          <div className="h1 text-sm-center text-lg-left">{services.title}</div>
          <div className="h4 text-gray-light d-none d-lg-block">
            {services.description}
          </div>
        </div>

        <div className="services">
          <div className="row">
            {servicesItems.map((item, id) => (
              <Link
                to={`/${lang}/services?service=${id}`}
                className="service-item"
                key={id}
              >
                <div className="service-icon">
                  <img
                    src={replaceSubDomain(item.icon_inactive.desktop)}
                    className="service-icon--default"
                    alt=""
                  />
                  <img
                    src={replaceSubDomain(item.icon_active.desktop)}
                    className="service-icon--hover"
                    alt=""
                  />
                </div>
                <h3 className="m-0">{item.title}</h3>
              </Link>
            ))}
          </div>
        </div>

        <h2 className="domains-title">{businessDomainTitle.title}</h2>
        <div className="tags">
          {domainItem.map((item, id) => (
            <div className="tag-item" key={id}>
              <div className="tag-item__label">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;
