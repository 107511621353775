import React from "react";
import { replaceSubDomain } from "../../utils";

const HowItems = (props) => {
  const {
    title,
    itemsList
  } = props;

  return (
    <div className="how-items">
    <section className="how-items-section section gray">
      <div className="container">
        <div className="h1  how-items-title text-md-center text-lg-left">{title}</div>
        <div className="how-items text-md-center text-lg-left">
          <div className="row m-0 ">
            {
              itemsList.map((item, id) => (
                <div className="col-md-12 col-lg-6  ml-0 pl-0" key={id}>
                  <div className="how-item">
                    <div className="how-image">
                      <img src={replaceSubDomain(item.icon_inactive.desktop)} alt="" />
                    </div>
                    <div className="h2">{item.title}</div>
                    <p className='description-strategy'>{item.description}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>

      </div>
    </section>
    </div>
  )
}

export default HowItems;
