import { globalHistory } from '@reach/router';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import iconEmoji from '../../../images/icon-emoji.png';
import ContactUsAndThanks from '../../CombineModals/ContactUsAndThanks';

const Vacation = (props) => {
  const [lang, setLange] = useState('');

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModalHandler = () => {
    setIsOpenModal(true);
  };
  const closeModalHandler = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    setLange(globalHistory.location.pathname.split('/')[1]);
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { title, subTitle, vacationList, allVacationsLink, empty } = props;

  return (
    <>
      <section className="section gray vacantion-section">
        <div className="container">
          <div className="row m-0">
            <div className="col-lg-6 p-0">
              <div className="h1 home-vacancy-title">{title}</div>
              <div className="h4 text-gray-light vacation-gray-subtitle">
                {subTitle}
              </div>
              <div className="custom-margin">
                <Link
                  to={`/${lang}/vacancy`}
                  className="btn btn-outline-primary btn-lg rounded-pill btn-all-vacancy"
                >
                  {allVacationsLink}
                </Link>
              </div>
            </div>
          </div>
          <div className="vacations">
            <Slider {...sliderSettings}>
              {vacationList.map((item, id) =>
                item.empty ? (
                  <div className="slider-item" key={item.id}>
                    <div className="empty-slider-item" key={item.id}>
                      <div className="title d-flex align-items-center">
                        {empty.title}{' '}
                        <img src={iconEmoji} className="ml-2" alt="iconEmoji" />
                      </div>
                      <div className="subtitle">{empty.subtitle}</div>
                      <button className="link" onClick={openModalHandler}>
                        {empty.link}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="slider-item" key={item.id}>
                    <div className="vacation">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <h2>
                            <Link to={`/${lang}/vacancies/vacancy/${item.vacancy_id?.id}`}>
                              {item.title}
                            </Link>
                          </h2>
                          <div className="vacation-subtitle">
                            {item.address}
                          </div>
                        </div>
                        <div className="flex-grow-0 ml-3">
                          <div className="vacation-icon">
                            <span role="img" aria-label="vacation-icon">
                              🔥
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="vacation-text">
                        {' '}
                        {item.pre_description}{' '}
                      </div>
                      <div className="d-flex align-items-center justify-content-between vacation-bottom">
                        <div>{item.date}</div>
                        <Link
                          to={`/${lang}/vacancies/vacancy/${item.vacancy_id?.id}`}
                          className="btn btn-primary rounded-pill vacancy-details-btn"
                        >
                          {item.button_title_vacancy}
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              )}
            </Slider>
          </div>
        </div>
      </section>
      <ContactUsAndThanks show={isOpenModal} onHide={closeModalHandler} />
    </>
  );
};

export default Vacation;
