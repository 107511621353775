import { globalHistory } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactUsAndThanks from '../components/CombineModals/ContactUsAndThanks';
import Feedback from '../components/Feedback';
import Hero from '../components/Hero';
import HowItems from '../components/HowItems';
import Layout from '../components/Layout';
import Partners from '../components/Partners';
import Portfolio from '../components/Portfolio';
import Service from '../components/Service';
import Vacations from '../components/VacanciesPageComponents/Vacancies';

const IndexPage = ({ pageContext: { data } }) => {
  const {
    content_footer,
    content_header,
    content_our_clients_say,
    organizing_the_workflow_items,
    our_business_domain_title,
    our_domain_item,
    our_services_items,
    partners,
    portfolio_items,
    portfolio_title,
    title_organizing_the_workflow,
    title_our_clients_say,
    title_our_services,
    vacancy_description,
    vacancy_list,
  } = data;
  const { t } = useTranslation();
  const [lang, setLange] = useState('');

  useEffect(() => {
    setLange(globalHistory.location.pathname.split('/')[1]);
  }, []);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModalHandler = () => {
    setIsOpenModal(true);
  };
  const closeModalHandler = () => {
    setIsOpenModal(false);
  };

  return (
    <Layout>
      <Hero
        image={content_header.image.desktop}
        btnText={content_header.button_title}
      >
        {content_header?.title}
      </Hero>

      <Service
        services={title_our_services}
        servicesItems={our_services_items}
        domainItem={our_domain_item}
        businessDomainTitle={our_business_domain_title}
      />

      <Portfolio
        title={portfolio_title?.title}
        allProjectsLink={portfolio_title?.all_projects_title}
        projectsList={portfolio_items}
      />

      <HowItems
        title={title_organizing_the_workflow?.title}
        itemsList={organizing_the_workflow_items}
      />

      <Feedback
        title={title_our_clients_say?.title}
        feedbackList={content_our_clients_say}
      />
      {lang !== 'en' && (
        <Vacations
          title={vacancy_description?.button_text}
          subTitle={vacancy_description?.description}
          allVacationsLink={vacancy_description?.button_text}
          vacationList={vacancy_list}
        />
      )}
      <div className="hero-contact-section">
        <section className="section text-center contact-us">
          <div className="container">
            <div className="h1">{content_footer.title}</div>
            <div className="pt-3">
              <button
                onClick={openModalHandler}
                className="btn btn-lg btn-dark rounded-pill mt-1 mb-2"
              >
                {content_footer.button_text}
              </button>
            </div>
          </div>
        </section>
      </div>

      <Partners partnersList={partners} />
      <ContactUsAndThanks show={isOpenModal} onHide={closeModalHandler} />
    </Layout>
  );
};

export default IndexPage;
